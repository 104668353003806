import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import GenerateCred from "./components/GenerateCred/generateCred";
import VerifyCreditnals from "./components/VerifyCreditnals/generateCred";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
export const isTestnet = false;
const socketURL =
  isTestnet === true
    ? "https://socket-stage.myearth.id"
    : "https://socket.myearth.id";
const socket = openSocket(socketURL, {
  transports: ["websocket", "polling", "flashsocket"],
});
let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(`I'm connected with the back-end`);
});
const {
  listenForUserData,
  listenForServiceProviderResponse,
  getApiKey,
  issuerDidgenerateqr,
  createIssuerDid,
  getUserCreditnals,
} = data;
let showNotification = true;

class App extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
    createDid: "",
    creditnals: "",
  };

  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false });
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }
    if (this.state.loggedIn) {
      localStorage.removeItem("email");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("user_name");
      localStorage.removeItem("dob");
      localStorage.removeItem("e_verified");
      localStorage.removeItem("trust_score");
      localStorage.removeItem("earth_id");
      localStorage.removeItem("m_verified");
      this.setState({
        loggedIn: false,
        btnText: "Login with Earth Id",
        qrcode: true,
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
      }, 0);
      this.redirectToHome();
    }
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
      this.setState({
        loggedIn: true,
        btnText: "LogOut",
      });
    }
    if (!this.state.apiKey) {
      this.state.apiKey = getApiKey();
    }
  };

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
  };

  verifyNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
  };

  userDenied = () => {
    this.setState({
      response: false,
      showModal: false,
    });
    setTimeout(() => {
      document.getElementById("exampleModal").click();
      if (showNotification === true) {
        showNotification = false;
        store.addNotification({
          title: "Error!",
          message: `User Didn't Authorize!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    }, 0);
  };

  listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false,
      });
    });

    listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("bytes", bytes);
      this.setState({
        showQr: false,
        showSpinner: false,
      });
      let creditnals;
      let sessionKey;
      if (bytes === "denied" || bytes.permission === "denied") {
        this.userDenied();
      } else if (
        bytes.permission === "granted" ||
        bytes === "issuerLogin" ||
        bytes === "credentialsgenerated" ||
        bytes === "verifylogin" ||
        bytes === "success"
      ) {
        this.setState({
          response: true,
          showSpinner: false,
          loggedIn: true,
          showModal: false,
          btnText: "LogOut",
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          let popMessage;
          if (showNotification === true) {
            if (bytes === "credentialsgenerated") {
              popMessage = "Credentials Generated";
            } else {
              popMessage = `Login Successful`;
            }
            showNotification = false;
            store.addNotification({
              title: "Success!",
              message: popMessage,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
            if (bytes.permission === "granted") {
              this.updateNotificationState();
            } else if (bytes === "verifylogin") {
              this.verifyNotificationState();
            }
          }
        }, 0);
      } else if (bytes.userDetails.credentialType != null) {
        console.log("bytes", bytes);
        sessionKey = bytes.sessionKey;
        await getUserCreditnals(bytes.userDetails)
          .then((data) => {
            this.userCreditnalsSuccess(bytes);

            creditnals = data;
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.setState({
          response: false,
          showModal: false,
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          if (showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        }, 0);
      }
    });
  };

  userCreditnalsSuccess = (data) => {
    if (data.userDetails.credentialType != null) {
      this.setState({
        response: true,
        showSpinner: false,
        loggedIn: true,
        showModal: false,
        btnText: "LogOut",
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
        let popMessage;
        if (showNotification === true) {
          popMessage = "Credentials Generated";
          showNotification = false;
          store.addNotification({
            title: "Success!",
            message: popMessage,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        } else {
          this.setState({
            response: false,
            showModal: false,
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            if (showNotification === true) {
              showNotification = false;
              store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
            }
          }, 0);
        }
      }, 0);
    }
  };

  generateQrCodeData = async () => {
    issuerDidgenerateqr("login", "loginDetailsEncryption")
      .then(async (data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  generateCredQrCodeData = () => {
    issuerDidgenerateqr("generateCredentials")
      .then((data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  qrcode = () => {
    let { qrCodeData } = this.state;
    return (
      <div className="qr-container">
        <img src={qrCodeData} alt="new" />
      </div>
    );
  };

  redirectToHome() {
    window.location.href = "/";
  }
  redirectToGenerateCred() {
    window.location.href = "/generateCred";
  }

  redirectToVerifyCreditnals() {
    window.location.href = "/verifyCreditnals";
  }

  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className="collapse navbar-collapse pull-right container"
            id="navbarTogglerDemo01"
          >
            <div className="clickable" onClick={() => this.redirectToHome()}>
              <img src={require("./fcart2.png")} alt="logo" className="logo" />{" "}
              <span className="logo-text">&nbsp;Issuer</span>
            </div>
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0"></ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}

            <div className="profileAccess">
              {window.location.pathname == "/" ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateQrCodeData}
                  >
                    {this.state.btnText}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateCredQrCodeData}
                  >
                    Generate Credentials
                  </button>
                </div>
              ) : window.location.pathname == "/verifyCreditnals" ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData}
                  >
                    Verify Creditanals
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateCredQrCodeData}
                  >
                    {" "}
                    Generate Credentials
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>
                </div>
              )}

              {this.state.loggedIn ? (
                <div className="dropDown">
                  {this.state.profileDrop ? (
                    <div>
                      <ul className="dropdown-profile">
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToProfile()}
                        >
                          My Profile
                        </li>
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToDocuments()}
                        >
                          {" "}
                          Submit Kyc Documents
                        </li>
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToGenerateCred()}
                        >
                          {" "}
                          Generate Credentials
                        </li>
                        <li className="dropdown-list" onClick={this.loader}>
                          LogOut
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </nav>
        <header>
          {window.location.pathname == "/" ? (
            <div className="hero-section">
              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.generateQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="hero-section">
              <div className="shoppingCart"></div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  {
                    <div>
                      {this.state.showModal ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4>
                              {this.state.modalHeading &&
                              !this.state.showSpinner
                                ? "Use Earth ID app to scan the QR code"
                                : "Waiting for user authorization"}
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="pop-upbox">
                              {showButton}
                              {this.state.response
                                ? null
                                : this.state.qrcode
                                ? this.generateCredQrCodeData()
                                : null}
                              {this.state.showQr ? (
                                this.qrcode()
                              ) : this.state.showSpinner ? (
                                <img
                                  className="spinner"
                                  src={require("./spinner.gif")}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary p-btn"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </div>
          )}
          <div className="content">
            <Router>
              <Switch>
                <Route path="/generateCred" component={GenerateCred} />
                <Route path="/verifyCreditnals" component={VerifyCreditnals} />
              </Switch>
            </Router>
          </div>
        </header>
      </div>
    );
  }
}

export default App;
